import { FC, useState, ReactNode } from 'react'
import { Placement } from '@popperjs/core'

import { Button, ButtonProps } from '../Button'
import { Dropdown } from '../Dropdown'

type Props = ButtonProps & {
    buttonText?: string
    placement?: Placement
    children: ReactNode
}

export const DropdownButton: FC<Props> = ({ buttonText, placement, children, ...props }) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)

    return (
        <div ref={setReferenceElement} onClick={(e) => e.stopPropagation()}>
            <Button
                {...props}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setShowDropdown(!showDropdown)
                }}
            >
                {buttonText}
            </Button>
            {showDropdown ? (
                <Dropdown
                    referenceElement={referenceElement}
                    onClose={() => setShowDropdown(false)}
                    placement={placement}
                >
                    {children}
                </Dropdown>
            ) : null}
        </div>
    )
}
