import {
    Children,
    FC,
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    cloneElement,
} from 'react'

import { Label } from 'ds/components/Label'
import { Button } from '../Button'
import styles from './style.module.scss'

type Props = {
    disabled?: boolean
    className?: string
    children: (ReactElement<any, JSXElementConstructor<any>> | null)[]
    label?: string
    required?: boolean
    value?: string
    error?: string
    onChange: (value: string) => void
    vertical?: boolean
}

export const SelectButtons: FC<Props> = ({
    disabled,
    className,
    label,
    required,
    value,
    error,
    children,
    onChange,
    vertical,
}) => {
    return (
        <div className={className}>
            {label ? <Label required={required}>{label}</Label> : null}
            <div className={vertical ? styles.vertical : styles.horizontal}>
                {Children.map(children, (child) => {
                    if (!child) return null
                    return cloneElement(child, {
                        disabled,
                        onClick: (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onChange(child.props.value)
                        },
                        variant:
                            child.props.value === value ? 'primarySubtle' : 'secondary',
                    })
                })}
            </div>
            <div className="small danger-500">{error}</div>
        </div>
    )
}

type OptionProps = {
    value: string
    children: ReactNode
}

export const OptionButton: FC<OptionProps> = ({
    value,
    children,
    // Need this for the overwrites happening above in SelectButtons
    ...props
}) => {
    return (
        <Button size="sm" value={value} {...props}>
            {children}
        </Button>
    )
}
