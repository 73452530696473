import { FC, ReactNode } from 'react'

type Props = {
    error?: ReactNode
    help?: ReactNode
}

export const InputInfo: FC<Props> = ({ error, help }) => {
    if (!error && !help) return null

    return (
        <>
            {help ? <div className="small neutral-500">{help}</div> : null}
            {error ? <div className="small danger-500">{error}</div> : null}
        </>
    )
}
