export const shortenStringMiddle = (
    str: string | undefined | null,
    desiredLength: number
) => {
    if (!str || str.length <= desiredLength) return str

    const actualDesiredLength = (desiredLength - 3) / 2

    const firstHalf = str.slice(0, Math.ceil(actualDesiredLength))
    const secondHalf = str.slice(str.length - Math.floor(actualDesiredLength))
    return firstHalf + '...' + secondHalf
}
