import { FC, ReactNode } from 'react'

import styles from './style.module.scss'

type Props = {
    children: ReactNode
}

export const Carousel: FC<Props> = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <div className="overflow-wrapper">
                <div className="flex-nowrap">{children}</div>
            </div>
        </div>
    )
}
