import { FC } from 'react'
import cx from 'classnames'

type Props = {
    className?: string
    checked?: boolean
    onChange: (v: boolean) => void
    children: React.ReactNode
    disabled?: boolean
}

export const Checkbox: FC<Props> = ({
    className,
    checked = true,
    onChange,
    children,
    disabled,
}) => {
    return (
        <div
            className={cx(className, 'd-flex align-items-center', {
                pointer: !disabled,
            })}
            onClick={() => !disabled && onChange(!checked)}
        >
            <input
                className="inline-sm ease"
                type="checkbox"
                checked={checked}
                disabled={disabled}
                readOnly
            />
            <label className={disabled ? '' : 'pointer'}>{children}</label>
        </div>
    )
}
