// This file should be used for things stored as just dates aka (YYYY-MM-DD) not date times
// MYSQL stores dates as (YYYY-MM-DD) so this is useful for that

import { endOfDay, format } from 'date-fns'
import { es } from 'date-fns/locale'

const dateRegex = /^\d{4}-\d{2}-\d{2}$/

type Options = { endOfDay?: boolean }

export const dateStringToDate = (
    dateString: string | null | undefined,
    options?: Options
): Date | undefined => {
    if (typeof dateString !== 'string' || !dateRegex.test(dateString)) return
    const [year, month, day] = dateString.split('-').map(Number)

    // Month value is 0 based
    const date = new Date(year, month - 1, day)
    if (options?.endOfDay) return endOfDay(date)

    return date
}

export const dateToDateString = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const formatDateStringShort = (dateString: string | null | undefined) => {
    const date = dateStringToDate(dateString)
    if (!date) return ''

    return format(date, 'd MMM y', { locale: es })
}

export const nulledDateToDateString = (date: Date | string | null): string | null => {
    if (!date) return null
    const date_ = new Date(date)
    if (isNaN(date_.getTime())) return null

    return dateToDateString(date_)
}

export const isValidDateString = (date: string | null | undefined): boolean => {
    if (!date || typeof date !== 'string') return false
    return dateRegex.test(date)
}
