import { FC, ReactNode } from 'react'
import cx from 'classnames'
import Color from 'color'

import styles from './style.module.scss'

export type BadgeVariant =
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success'
    | 'neutral'

type Props = {
    variant?: BadgeVariant
    className?: string
    children?: ReactNode
    bgColor?: string
    onClick?: () => void
    title?: string
    size?: 'sm' | 'lg'
}

export const Badge: FC<Props> = ({
    variant,
    className,
    children,
    bgColor,
    onClick,
    title,
    size,
}) => {
    const color = bgColor
        ? Color(bgColor).isDark()
            ? 'var(--light-color)'
            : 'var(--dark-color)'
        : undefined

    return (
        <div
            className={cx(
                styles.badge,
                {
                    [styles.primary]: (!variant && !bgColor) || variant === 'primary',
                    [styles.secondary]: variant === 'secondary',
                    [styles.danger]: variant === 'danger',
                    [styles.warning]: variant === 'warning',
                    [styles.success]: variant === 'success',
                    [styles.neutral]: variant === 'neutral',
                    [styles.clickable]: !!onClick,
                    [styles.large]: size === 'lg',
                },
                className
            )}
            onClick={onClick}
            style={{
                backgroundColor: bgColor,
                color,
            }}
            title={title}
        >
            {children}
        </div>
    )
}
