import { FC, ReactNode } from 'react'
import cx from 'classnames'

import { Label } from 'ds/components/Label'
import { FormContext } from 'lib/hooks/useForm'
import { InputInfo } from '../InputInfo'

type RadioItem = {
    value: string
    help?: string
    body: ReactNode
}

type Props = {
    className?: string
    horizontal?: boolean
    label?: string
    error?: string
    required?: boolean
    formContext?: FormContext

    value: string | undefined

    onChange: (value: string) => void
    items: RadioItem[]
}

export const RadioGroup: FC<Props> = ({
    className,
    value,
    label,
    error,
    items,
    onChange,
    required,
    horizontal,
    formContext,
}) => {
    const itemClass = horizontal ? 'inline-md' : 'stacked-sm'

    return (
        <div className={cx(className, { ['d-flex']: horizontal })}>
            {label ? (
                <Label required={required || formContext?.required}>{label}</Label>
            ) : null}
            {items.map((item) => (
                <Radio
                    className={itemClass}
                    key={item.value}
                    checked={value === item.value}
                    onClick={() => onChange(item.value)}
                    help={item.help}
                >
                    {item.body}
                </Radio>
            ))}
            <InputInfo error={error || formContext?.error} />
        </div>
    )
}

type RadioProps = {
    className: string
    checked: boolean
    onClick: () => void
    children: ReactNode

    help?: string
}

const Radio: FC<RadioProps> = ({ checked, onClick, help, children, className }) => {
    return (
        <div className={`pointer ${className}`} onClick={onClick}>
            <div className="d-flex align-items-center">
                {checked ? (
                    <div className="inline-sm primary-500 fs-24">
                        <ion-icon name="radio-button-on-outline"></ion-icon>
                    </div>
                ) : (
                    <div className="inline-sm neutral-500 fs-24">
                        <ion-icon name="radio-button-off-outline"></ion-icon>
                    </div>
                )}
                <div>{children}</div>
            </div>
            <div className="small neutral-500">{help}</div>
        </div>
    )
}
