import cx from 'classnames'
import styles from './style.module.scss'
import { ReactElement } from 'react'

type Props = {
    className?: string
    label: ReactElement | string
    value: boolean
    loading?: boolean
    onChange: (value: boolean) => void
    onClick?: () => void
    disabled?: boolean
    size?: 'sm' | 'md' | 'lg'
}

export const Switch = ({
    label,
    value,
    loading,
    onChange,
    onClick,
    disabled,
    className,
    size,
}: Props) => {
    return (
        <div className={className}>
            <label
                className={cx(styles.switch, {
                    [styles.disabled]: disabled || loading,
                    [styles.small]: size === 'sm',
                    [styles.medium]: size === 'md',
                })}
            >
                <input
                    className={styles.input}
                    type="checkbox"
                    disabled={disabled || loading}
                    checked={value}
                    onChange={() => {}}
                    onClick={(e) => {
                        e.preventDefault()
                        if (onClick) onClick()
                        onChange(!value)
                    }}
                />
                <span className={cx(styles.slider, { [styles.checked]: value })} />
            </label>
            <span className={styles.label}>{label}</span>
        </div>
    )
}
