import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import { useEffect, useState } from 'react'
import { Input } from '../Input'
import { useDebounce } from 'use-debounce'

interface Props
    extends Omit<
        DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        'onChange' | 'value'
    > {
    onChange: (val: string) => void
    value: string | undefined
    wait?: number
    label?: string
}

export const InputDebounced = ({
    onChange,
    value,
    wait = 500,
    label,
    ...props
}: Props) => {
    const [inputValue, setInputValue] = useState(value)

    const [debouncedValue] = useDebounce(inputValue, wait)

    useEffect(() => {
        if (value !== debouncedValue) onChange?.(debouncedValue!)
    }, [debouncedValue, value, onChange])

    return (
        <Input
            {...props}
            label={label}
            value={inputValue}
            onChange={(v) => setInputValue(v)}
        />
    )
}
