import { FC, useState } from 'react'
import { usePopper } from 'react-popper'
import cx from 'classnames'
import { useEventListener } from 'usehooks-ts'
import { Placement } from '@popperjs/core'

import styles from './style.module.scss'

const ESCAPE_KEYS = new Set(['27', 'Escape'])

type Props = {
    children: React.ReactNode
    referenceElement: HTMLElement | null
    placement?: Placement
    onClose: () => void
}

export const Dropdown: FC<Props> = ({
    children,
    referenceElement,
    placement = 'bottom-start',
    onClose,
}) => {
    useEventListener('mousedown', (e) => {
        if (!referenceElement || !referenceElement.contains(e.target as Node)) {
            onClose()
        }
    })

    useEventListener('keydown', ({ key }) => {
        if (ESCAPE_KEYS.has(key)) onClose()
    })

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    )
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(
        null
    )

    const { styles: popperStyles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
            placement,
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        padding: 16,
                    },
                },
                {
                    name: 'arrow',
                    options: { element: arrowElement },
                },
            ],
        }
    )

    return (
        <div className={styles.wrapper}>
            <div
                className={cx(styles.content, 'box-shadow')}
                style={popperStyles.popper}
                ref={setPopperElement}
                {...attributes.popper}
            >
                {children}
                <div
                    ref={setArrowElement}
                    className={styles.arrow}
                    style={popperStyles.arrow}
                />
            </div>
        </div>
    )
}
