const fuzzy = (str, s) => {
    str = String(str)
    s = String(s)

    var hay = str.toLowerCase(),
        i = 0,
        n = -1,
        l
    s = s.toLowerCase()
    for (; (l = s[i++]);) if (!~(n = hay.indexOf(l, n + 1))) return false
    return true
}

export { fuzzy }
