type Params = { key?: string; dir?: string }

export const getSortByString = ({ key, dir }: Params): string | null => {
    if (!key?.trim()) return null
    return `${dir === 'desc' ? '-' : ''}${key}`
}

export const getSortByFromString = (
    sortBy: string | undefined | null
): { id: string; desc: boolean }[] => {
    if (!sortBy?.trim()) return []

    const desc = sortBy.startsWith('-')

    return [{ id: desc ? sortBy.slice(1) : sortBy, desc }]
}
