import { FC, ReactNode } from 'react'
import cx from 'classnames'

import Head from 'next/head'
import { Button } from '../Button'
import { HeaderWithAction } from '../HeaderWithAction'
import styles from './style.module.scss'

type Props = {
    title: string
    titleAppend?: ReactNode
    secondary?: ReactNode
    action?: ReactNode
    showBack?: boolean
}

export const PageHeader: FC<Props> = ({
    title,
    titleAppend,
    secondary,
    action,
    showBack,
}) => {
    return (
        <div className={cx('stacked-sm', styles.header)}>
            <Head>
                <title>{title} - Savio</title>
            </Head>
            {showBack ? (
                <Button
                    size="sm"
                    variant="secondaryText"
                    onClick={() => window.history.back()}
                >
                    ← Regresar
                </Button>
            ) : null}

            <HeaderWithAction
                title={
                    <>
                        <h1>
                            {title} {titleAppend}
                        </h1>
                        {secondary ? <h3 className="neutral-600">{secondary}</h3> : null}
                    </>
                }
                action={action}
            />
        </div>
    )
}
