import { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'

import styles from './style.module.scss'

type Props = {
    multiple?: boolean
    disabled?: boolean
    acceptOnlyCsv?: boolean

    onDrop: (acceptedFiles: File[]) => void
}

export const Dropzone: FC<Props> = ({
    onDrop,
    multiple,
    acceptOnlyCsv,
    disabled,
}) => {
    const accept = acceptOnlyCsv ? { ['text/csv']: ['.csv'] } : undefined

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: multiple ?? true,
        accept,
        disabled,
    })

    return (
        <section className={styles.container}>
            <div
                {...getRootProps({
                    className: cx(styles.dropzone, {
                        [styles.disabled]: disabled,
                    }),
                })}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Suelta aquí</p>
                ) : (
                    <p>
                        Arrastra tus archivos aquí, o haz click para seleccionar
                    </p>
                )}
            </div>
        </section>
    )
}
