import { FC, useMemo } from 'react'

import { Table } from 'ds/components/Table'

type Props = {
    data: any[]
}

export const DataTable: FC<Props> = ({ data }) => {
    const columns = useMemo(
        () =>
            data.length
                ? Object.keys(data[0]).map((key) => ({
                      header: key,
                      accessorFn: (d) => d[key],
                  }))
                : [],
        [data]
    )

    return (
        <div>
            <div className="stacked-lg">
                <Table data={data} columns={columns} />
            </div>
        </div>
    )
}
