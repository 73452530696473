import { FC, ReactNode } from 'react'

import styles from './style.module.scss'

type Props = { children: ReactNode; required?: boolean }

export const Label: FC<Props> = ({ children, required }) => {
    return (
        <label className={styles.label}>
            {children}
            {required ? '*' : ''}
        </label>
    )
}
