import { FC, ReactNode } from 'react'

import styles from './style.module.scss'

type Props = {
    className?: string
    label: string
    children: ReactNode
    noBottomMargin?: boolean
}

export const Kpi: FC<Props> = ({ label, children, noBottomMargin, className }) => {
    return (
        <div className={`inline ${className} ${noBottomMargin ? '' : 'stacked-md'}`}>
            <div className="lh-20 fs-14 stacked-xs neutral-700">{label}</div>
            <div className={styles.value}>{children}</div>
        </div>
    )
}
