import cx from 'classnames'
import styles from './style.module.scss'
import { LinkController } from '../LinkController'
import { FC } from 'react'

type TextLinkVariants =
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success'
    | 'neutral'

type Props = {
    href?: string
    className?: string
    variant?: TextLinkVariants
    children: React.ReactNode
    onClick?: () => void
}

export const TextLink: FC<Props> = ({
    href,
    variant = 'primary',
    className,
    ...props
}) => {
    const className_ = cx(styles.main, className, {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.danger]: variant === 'danger',
        [styles.warning]: variant === 'warning',
        [styles.success]: variant === 'success',
        [styles.neutral]: variant === 'neutral',
    })

    if (!href) return <div className={className_} {...props} />

    return <LinkController href={href} className={className_} {...props} />
}
