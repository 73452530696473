import { FC, ReactNode, useState } from 'react'
import cx from 'classnames'
import { useTimeout } from 'usehooks-ts'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Icon } from '../Icon'
import styles from './style.module.scss'

type Props = {
    className?: string
    value: string
    children: ReactNode
    hideCopyText?: boolean
    backgroundClass?: string

    onClick?: () => void
}

export const ClickToCopy: FC<Props> = ({
    className,
    value,
    children,
    hideCopyText,
    backgroundClass,
    onClick,
}) => {
    const [copied, setCopied] = useState(false)
    useTimeout(() => setCopied(false), copied ? 2000 : null)

    return (
        <div
            className={className}
            onClick={(e) => {
                e.stopPropagation()
                onClick?.()
            }}
        >
            <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                <div
                    className={cx(
                        'd-flex align-center small pointer',
                        backgroundClass || 'bg-neutral-100',
                        styles.container
                    )}
                >
                    <div className="flex-fill inline-sm neutral-700">{children}</div>
                    {hideCopyText ? null : <div className="inline-xs">Copiar</div>}
                    {copied ? (
                        <Icon icon="ClipboardCheckFill" />
                    ) : (
                        <Icon icon="Clipboard" />
                    )}
                </div>
            </CopyToClipboard>
        </div>
    )
}
