import { FC } from 'react'

import { Button } from 'ds/components'

type Props = { table: any }

export const SelectableHeader: FC<Props> = ({ table }) => {
    const allSelected = table.getIsAllRowsSelected()
    const icon = allSelected
        ? 'check-square-fill'
        : table.getIsSomeRowsSelected()
        ? 'dash-square'
        : 'square'

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <Button
                style={{ fontSize: '14px' }}
                size="sm"
                variant={allSelected ? 'primaryText' : 'secondaryText'}
                icon={icon}
                onClick={table.getToggleAllRowsSelectedHandler()}
            />
        </span>
    )
}
