import styles from './style.module.scss'
import { Tooltip } from '../Tooltip'
import { shortenStringMiddle } from 'lib/utils/shortenStringMiddle'

type Props = {
    filename: string
    onDelete?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const AttachmentIcon = ({ filename, onDelete }: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className="inline middle inline-md">
                <ion-icon
                    name="document-attach-outline"
                    style={{ fontSize: '16px', color: '#555' }}
                />
            </div>
            <div className="inline inline-md small neutral-600">
                <Tooltip trigger={shortenStringMiddle(filename, 30)}>{filename}</Tooltip>
            </div>
            {onDelete ? (
                <div className="inline middle">
                    <div onClick={onDelete} className="pointer">
                        <ion-icon name="close-outline" style={{ fontSize: '16px' }} />
                    </div>
                </div>
            ) : null}
        </div>
    )
}
