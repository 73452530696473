import { FC } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'

type Props = {
    className?: string
    scale?: string | number
    variant?: 'neutral'
}

export const Spinner: FC<Props> = ({ scale, variant, className }) => {
    return (
        <div className={cx('center', className)}>
            <div
                className={cx(styles.spinner, {
                    [styles.neutral]: variant === 'neutral',
                })}
                style={{ transform: `scale(${scale})` }}
            >
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}
