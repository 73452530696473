import cx from 'classnames'
import Link from 'next/link'
import React, { FC, JSXElementConstructor, ReactElement, ReactNode } from 'react'
import styles from './style.module.scss'
import { Button } from '../Button'

type TabProps = {
    className?: string
    active?: boolean
    href?: string
    disabled?: boolean
    children: ReactNode
    value: string | number | boolean
    onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void
}

export const Tab: FC<TabProps> = ({
    className,
    active,
    href,
    children,
    disabled,
    ...props
}) => {
    const tab = (
        <span
            className={cx(styles.tab, className, {
                [styles.active]: active,
            })}
            {...props}
        >
            <Button
                disabled={disabled}
                variant={active ? 'primaryText' : 'secondaryText'}
                size="sm"
            >
                {children}
            </Button>
            {active && <div className={styles.border}></div>}
        </span>
    )

    return href ? <Link href={href}>{tab}</Link> : tab
}

type TabsProps = {
    className?: string
    type?: 'horizontal' | 'vertical'
    activeValue: string | number | boolean
    children: ReactElement<any, JSXElementConstructor<any>>[]
}

export const Tabs: FC<TabsProps> = ({ className, type, activeValue, children }) => {
    return (
        <div
            className={cx(
                'overflow-wrapper',
                styles.tabs,
                { [styles.horizontal]: !type || type === 'horizontal' },
                { [styles.vertical]: type === 'vertical' },
                className
            )}
        >
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child))
                    return React.cloneElement(child, {
                        active: child.props.value === activeValue,
                    })

                return child
            })}
        </div>
    )
}
