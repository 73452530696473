import { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'

type Props = {
    title: string
    children: ReactNode
    right?: boolean
}

export const KeyValue: FC<Props> = ({ title, children, right }) => {
    return (
        <div className="d-flex flex-column flex-md-row stacked-xs">
            <div className={cx(styles.label, { ['flex-fill']: right })}>
                <span className="neutral-600">{title}</span>
            </div>
            <div className={cx({ ['flex-fill']: !right }, 'pre-wrap')}>
                {children}
            </div>
        </div>
    )
}
