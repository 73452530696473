import { useMemo } from 'react'
import { parseAsTimestamp, useQueryState } from 'next-usequerystate'
import { endOfDay, startOfDay } from 'date-fns'

import { DatePicker } from '../DatePicker'
import { Option, Select } from '../Select'

import { getDateRangeOptions } from 'lib/utils/getDateRangeOptions'

type Props = {
    className?: string
    label?: string
    value: string
    excludeAll?: boolean
    onChange: (value: {
        label: string
        value: string
        start: Date | null
        end: Date | null
    }) => void
}

export const SelectDateRange = ({
    className,
    label,
    value,
    onChange,
    excludeAll,
}: Props) => {
    const [start, setStart] = useQueryState('start', parseAsTimestamp)
    const [end, setEnd] = useQueryState('end', parseAsTimestamp)

    const { options, optionsMap } = useMemo(() => getDateRangeOptions(), [])

    return (
        <div className={className}>
            <Select
                label={label}
                value={value}
                onChange={(v) => {
                    const selectedDateRange = optionsMap[v]
                    onChange({
                        label: selectedDateRange.label,
                        value: selectedDateRange.value,
                        start: selectedDateRange.start,
                        end: selectedDateRange.end,
                    })
                }}
            >
                {options.map((option) =>
                    !excludeAll || option.value !== 'all' ? (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ) : null
                )}
            </Select>
            {value === 'custom' && (
                <div className="d-flex padding-top-sm">
                    <div className="inline-sm">
                        <DatePicker
                            placeholder="Inicio"
                            value={start}
                            onChange={(v) => {
                                const _start = v ? startOfDay(v) : null
                                setStart(_start)
                                onChange({
                                    label: 'Custom',
                                    value: 'custom',
                                    start: _start,
                                    end,
                                })
                            }}
                        />
                    </div>
                    <div>
                        <DatePicker
                            placeholder="Fin"
                            value={end}
                            onChange={(v) => {
                                const _end = v ? endOfDay(v) : null
                                setEnd(_end)
                                onChange({
                                    label: 'Custom',
                                    value: 'custom',
                                    start,
                                    end: _end,
                                })
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
