import exportFromJSON from 'export-from-json'
import { formatDateTimestamp } from './formatDateTimestamp'

const downloadToCsv = ({ data, getSubRows, filename }) => {
    const exportType = exportFromJSON.types.csv
    const now = new Date()
    const fileName = filename + '-' + formatDateTimestamp(now)

    let dataToExport = data.map((row) => {
        if (getSubRows) {
            const rowsToExport = []
            flattenSubRows(rowsToExport, row.exportToExcel, getSubRows)
            return rowsToExport
        }
        return [row.exportToExcel]
    })

    const flattenArray = (array) => {
        return array.reduce((acc, val) => acc.concat(val), [])
    }

    exportFromJSON({
        data: flattenArray(dataToExport),
        fileName,
        exportType,
    })
}

const flattenSubRows = (subRows, currRow, getSubRow) => {
    const currSubRows = getSubRow(currRow)
    delete currRow.subRows
    delete currRow.children
    subRows.push(currRow)

    if (currSubRows) {
        currSubRows.forEach((subRow) => {
            flattenSubRows(subRows, subRow, getSubRow)
        })
    }
}

export { downloadToCsv }
