import { Button } from '../Button'

const Pagination = ({
    isLoading,
    offset,
    result_count,
    total_count,
    onPrevPage,
    onNextPage,
}) => {
    const hasResults = Number(result_count) >= 1
    const itemStartCount = hasResults ? Number(offset) + 1 : Number(offset)

    const itemEndCount = Number(offset) + Number(result_count)
    const canPrevPage = itemStartCount > 1
    const canNextPage = itemEndCount < total_count

    return (
        <div className="d-flex align-items-center">
            <div className="inline-sm small neutral-600">
                {isLoading
                    ? 'Cargando...'
                    : `${itemStartCount}-${itemEndCount} de ${total_count}`}
            </div>
            <Button
                variant="secondaryText"
                size="sm"
                icon="chevron-left"
                onClick={onPrevPage}
                disabled={!canPrevPage}
                className="inline-xs"
            />
            <Button
                variant="secondaryText"
                size="sm"
                icon="chevron-right"
                onClick={onNextPage}
                disabled={!canNextPage}
            />
        </div>
    )
}

export { Pagination }
