import cx from 'classnames'
import styles from './style.module.scss'

const ProgressBar = ({ height = '10px', width, color = 'primary-500' }) => {
    return (
        <div
            className={cx(
                [styles.progress],
                'stacked-md',
                'd-flex',
                'bg-neutral-200'
            )}
            style={{ height }}
        >
            <div
                className={cx([styles.progressbar], `bg-${color}`)}
                style={{ height, flexBasis: width }}
            />
        </div>
    )
}

export { ProgressBar }
