import { FC, useState } from 'react'

import { Spinner, Dropzone, AttachmentIcon, Label, ErrorMessage } from 'ds/components'

import { uploadFileToS3 } from 'lib/utils/uploadFileToS3'

type Props = {
    className?: string
    s3directory?: string
    value?: string[]
    label?: string
    error?: string
    placeHolderValues?: string[]

    onChange: (files: string[]) => void
}

export const FileInputMultiple: FC<Props> = ({
    label,
    error,
    value = [],
    onChange,
    s3directory,
    placeHolderValues,
    className,
}) => {
    const [loading, setLoading] = useState(false)

    const handleUpload = async (acceptedFiles) => {
        const uploadedFiles: string[] = []

        setLoading(true)
        await Promise.all(
            acceptedFiles.map(async (file) => {
                const fileUrl = await uploadFileToS3(file, s3directory)
                if (!fileUrl) {
                    console.error(`Algo salió mal uploading ${file.name}`)
                    return
                }

                uploadedFiles.push(fileUrl)
            })
        )

        onChange(value.concat(uploadedFiles))
        setLoading(false)
    }

    const renderAttachment = (url: string, idx?: number) => (
        <div key={url} className="inline inline-md stacked-sm">
            <AttachmentIcon
                filename={url.split('/').pop() as string}
                onDelete={
                    idx === undefined
                        ? undefined
                        : (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              value.splice(idx, 1)
                              onChange(value)
                          }
                }
            />
        </div>
    )

    return (
        <div className={className}>
            <div>
                {placeHolderValues?.map((url) => renderAttachment(url))}
                {value.map((url, i) => (url ? renderAttachment(url, i) : null))}

                {loading ? (
                    <div className="inline inline-md stacked-sm">
                        <div className="inline inline-md">
                            <AttachmentIcon filename="Procesando..." />
                        </div>
                        <div className="inline middle">
                            <Spinner scale="0.5" />
                        </div>
                    </div>
                ) : null}
                <ErrorMessage>{error}</ErrorMessage>
            </div>
            <div className="stacked-md">
                <Label>{label}</Label>
                <Dropzone onDrop={handleUpload} />
            </div>
        </div>
    )
}
