import { ReactNode } from 'react'
import { ErrorMessage } from '../ErrorMessage'
import styles from './style.module.scss'
import { Label } from 'ds/components/Label'

type Props = {
    className?: string
    rows?: number
    error?: string
    required?: boolean
    disabled?: boolean
    label: ReactNode
    value: string

    onBlur?: () => void
    onChange: (value: any) => void
}

export const Textarea = ({
    className,
    label,
    required,
    error,
    value,
    disabled,
    rows,
    onBlur,
    onChange,
}: Props) => {
    return (
        <div className={className}>
            <Label>
                {label}
                {required ? '* ' : ''}
            </Label>
            <textarea
                disabled={disabled}
                className={styles.textarea}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                value={value}
                rows={rows}
            />
            <ErrorMessage>{error}</ErrorMessage>
        </div>
    )
}
