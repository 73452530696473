import { FC } from 'react'

import styles from './style.module.scss'

type Props = {
    height?: number
}

export const Logo: FC<Props> = ({ height = 56 }) => {
    return (
        <div
            className={styles.logoImg}
            style={{ height: `${height}px`, width: `${height * 2.5}px` }}
        />
    )
}
