import {
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfYear,
    format,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subYears,
} from 'date-fns'

type Option = {
    label: string
    value: string
    start: Date | null
    end: Date | null
}

const generateMonthOption = (date: Date): Option => {
    return {
        label: date.toLocaleDateString('es-es', {
            month: 'long',
            year: 'numeric',
        }),
        value: format(date, 'yyyy-MM'),
        start: startOfMonth(date),
        end: endOfMonth(date),
    }
}

export const getDefaultDateRange = (value?: string) => {
    const { optionsMap } = getDateRangeOptions()
    if (value && value in optionsMap) return optionsMap[value]
    return optionsMap['this-month']
}

export const getDateRangeOptions = (): {
    options: Option[]
    optionsMap: Record<string, Option>
} => {
    const date = new Date()

    const options = [
        generateMonthOption(date),
        generateMonthOption(subMonths(date, 1)),
        generateMonthOption(subMonths(date, 2)),
        generateMonthOption(subMonths(date, 3)),
        generateMonthOption(subMonths(date, 4)),
        generateMonthOption(subMonths(date, 5)),
        {
            label: 'Desde hace 30 días',
            value: 'since-30d',
            start: startOfDay(subDays(date, 30)),
            end: date,
        },
        {
            label: 'Desde hace 60 días',
            value: 'since-60d',
            start: startOfDay(subDays(date, 60)),
            end: date,
        },
        {
            label: 'Desde hace 90 días',
            value: 'since-90d',
            start: startOfDay(subDays(date, 90)),
            end: date,
        },
        {
            label: 'Desde hace 6 meses',
            value: 'since-6m',
            start: startOfMonth(subMonths(date, 6)),
            end: date,
        },
        {
            label: 'Desde hace 12 meses',
            value: 'since-12m',
            start: startOfMonth(subMonths(date, 12)),
            end: date,
        },
        {
            label: `YTD`,
            value: 'ytd',
            start: startOfYear(date),
            end: endOfDay(date),
        },

        {
            label: 'Este mes',
            value: 'this-month',
            start: startOfMonth(date),
            end: endOfMonth(date),
        },
        {
            label: 'Mes pasado',
            value: 'last-month',
            start: startOfMonth(subMonths(date, 1)),
            end: endOfMonth(subMonths(date, 1)),
        },

        {
            label: `Trimestre QTD`,
            value: 'qtd',
            start: startOfQuarter(date),
            end: date,
        },
        {
            label: `Trimestre pasado`,
            value: 'last-qtr',
            start: startOfQuarter(subQuarters(date, 1)),
            end: endOfQuarter(subQuarters(date, 1)),
        },

        {
            label: `Este año`,
            value: 'this-year',
            start: startOfYear(date),
            end: endOfYear(date),
        },
        {
            label: `Año pasado`,
            value: 'last-year',
            start: startOfYear(subYears(date, 1)),
            end: endOfYear(subYears(date, 1)),
        },
        {
            label: `Todo`,
            value: 'all',
            start: null,
            end: null,
        },

        {
            label: `Custom`,
            value: 'custom',
            start: startOfMonth(date),
            end: endOfMonth(date),
        },
    ]

    const optionsMap = options.reduce(
        (acc, option) => ((acc[option.value] = option), acc),
        {} as Record<string, Option>
    )

    return { options, optionsMap }
}
