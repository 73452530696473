import { MouseEvent, ReactNode, useMemo, useRef, useState } from 'react'
import cx from 'classnames'

import { useCombobox, useMultipleSelection } from 'downshift'
import { Label } from 'ds/components/Label'
import styles from './style.module.scss'
import { Icon } from '../Icon'
import { InputInfo } from '../InputInfo'

import { fuzzy } from 'lib/utils/fuzzy'

const getFilteredItems = (
    items: Item[],
    itemToString: (item: Item) => string,
    inputValue: string
) => {
    if (!inputValue) return items

    return items.filter((item) => {
        const matchesInput = fuzzy(itemToString(item), inputValue)
        return matchesInput
    })
}

type Item = any

type Props = {
    items: Item[]
    selectedItems: Item[]

    label?: string
    className?: string
    disabled?: boolean
    error?: string
    help?: ReactNode
    placeholder?: string
    required?: boolean
    suggestedAction?: ReactNode
    maxWidth?: number
    emptyText?: string

    getItemId: (item: Item) => string | number
    itemToString: (item: Item) => string
    renderItem?: (item: Item) => ReactNode
    renderSelectedItem?: (item: Item, onRemove: (e: MouseEvent) => void) => ReactNode
    setSelectedItems: (value: Item[]) => void
    getItemDescription?: (item: Item) => string | undefined
}

const SelectMultipleInput = ({
    label,
    placeholder,
    selectedItems,
    disabled,
    required,
    setSelectedItems,
    error,
    help,
    items,
    getItemId,
    itemToString,
    renderItem,
    renderSelectedItem,
    getItemDescription,
    suggestedAction,
    className,
    maxWidth,
    emptyText,
}: Props) => {
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef<HTMLInputElement | null>(null)

    const filteredInputItems = useMemo(
        () => getFilteredItems(items, itemToString, inputValue),
        [inputValue, itemToString, items]
    )

    const selectedItemIds = useMemo(
        () => new Set(selectedItems.map((i) => getItemId(i))),
        [selectedItems, getItemId]
    )

    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
        useMultipleSelection({
            selectedItems,
            onStateChange: ({ selectedItems: newSelectedItems, type }) => {
                switch (type) {
                    case useMultipleSelection.stateChangeTypes
                        .SelectedItemKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                    case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                        setSelectedItems(newSelectedItems ?? [])
                        break
                    default:
                        break
                }
            },
        })

    const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } =
        useCombobox({
            items: filteredInputItems,
            inputValue,
            selectedItem: null,
            itemToString: (item) => (item ? itemToString(item) : ''),
            defaultHighlightedIndex: 0, // after selection, highlight the first item.
            stateReducer: (state, actionAndChanges) => {
                const { changes, type } = actionAndChanges
                switch (type) {
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                        return {
                            ...changes,
                            isOpen: true, // keep the menu open after selection.
                            highlightedIndex: state.highlightedIndex, // with the first option highlighted.
                        }
                    default:
                        return changes
                }
            },
            onStateChange({
                inputValue: newInputValue,
                selectedItem: newSelectedItem,
                type,
            }) {
                switch (type) {
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick: {
                        if (selectedItemIds.has(getItemId(newSelectedItem))) {
                            removeSelectedItem(newSelectedItem)
                        } else if (newSelectedItem) {
                            setSelectedItems([...selectedItems, newSelectedItem])
                        }
                        if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
                            setInputValue('')
                            inputRef?.current?.blur()
                        }
                        break
                    }
                    case useCombobox.stateChangeTypes.InputBlur:
                        setInputValue('')
                        break
                    case useCombobox.stateChangeTypes.InputChange:
                        setInputValue(newInputValue || '')
                        break
                    default:
                        break
                }
            },
        })

    return (
        <div className={cx(className, styles.wrapper)}>
            <div>
                {label ? <Label required={required}>{label}</Label> : null}

                <div
                    className={cx(styles.container, 'ease', disabled && styles.disabled)}
                >
                    <div className="d-flex flex-wrap align-items-center">
                        {selectedItems.map((item, index) => {
                            return (
                                <span
                                    className={cx(
                                        { [styles.selectedItem]: !renderSelectedItem },
                                        'inline-xs',
                                        'inset-y-xxs'
                                    )}
                                    key={getItemId(item)}
                                    {...getSelectedItemProps({
                                        selectedItem: item,
                                        index,
                                    })}
                                >
                                    {renderSelectedItem ? (
                                        renderSelectedItem(item, (e) => {
                                            e.stopPropagation()
                                            removeSelectedItem(item)
                                        })
                                    ) : (
                                        <>
                                            {itemToString(item)}
                                            <span
                                                className="px-1 pointer"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (disabled) return
                                                    removeSelectedItem(item)
                                                }}
                                            >
                                                &#10005;
                                            </span>
                                        </>
                                    )}
                                </span>
                            )
                        })}
                        <div className="flex-fill lh-24">
                            <input
                                {...getInputProps(
                                    getDropdownProps({
                                        className: cx(styles.input, 'ease'),
                                        ref: inputRef,
                                        placeholder: selectedItems.length
                                            ? undefined
                                            : placeholder,
                                        disabled,
                                        preventKeyAction: isOpen,
                                        style: {
                                            maxWidth: selectedItems.length
                                                ? maxWidth
                                                : undefined,
                                        },
                                    })
                                )}
                            />
                        </div>
                    </div>
                </div>

                <ul className={cx(styles.items, { visible: isOpen })} {...getMenuProps()}>
                    {isOpen && !disabled && Boolean(filteredInputItems.length)
                        ? filteredInputItems.map((item, index) => {
                              const itemId = getItemId(item)
                              const itemDescription = getItemDescription?.(item)

                              const isSelected = selectedItemIds.has(itemId)

                              return (
                                  <li
                                      className={cx(styles.item, 'ease', {
                                          [styles.highlight]: highlightedIndex === index,
                                      })}
                                      {...getItemProps({ index, item })}
                                      key={itemId}
                                  >
                                      <div className="d-flex align-items-center">
                                          {isSelected ? (
                                              <Icon
                                                  icon="CheckSquareFill"
                                                  className="primary-500 inline-sm"
                                              />
                                          ) : (
                                              <Icon
                                                  icon="Square"
                                                  className="neutral-400 inline-sm"
                                              />
                                          )}

                                          <div className="flex-fill">
                                              {renderItem?.(item) || itemToString(item)}
                                              {itemDescription ? (
                                                  <div className="small">
                                                      {itemDescription}
                                                  </div>
                                              ) : null}
                                          </div>
                                      </div>
                                  </li>
                              )
                          })
                        : null}
                    {isOpen && !disabled && !filteredInputItems.length ? (
                        <li className={styles.item}>
                            {emptyText || 'No hay mas opciones.'}
                        </li>
                    ) : null}
                    {isOpen && !disabled && suggestedAction ? (
                        <li className={styles.item}>{suggestedAction}</li>
                    ) : null}
                </ul>
            </div>
            <InputInfo error={error} help={help} />
        </div>
    )
}

export { SelectMultipleInput }
