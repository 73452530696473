import { FC, ReactNode } from 'react'
import cx from 'classnames'
import { useEventListener } from 'usehooks-ts'
import { createPortal } from 'react-dom'

import { Button } from '../Button'
import styles from './style.module.scss'

const ESCAPE_KEYS = new Set(['27', 'Escape'])

type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

type Props = {
    className?: string
    title?: string
    children: ReactNode
    size?: ModalSize
    onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

export const Modal: FC<Props> = ({ className, title, children, size = 'lg', onClose }) => {
    const modalRoot = document.getElementById('modal-root')

    useEventListener('keydown', ({ key }) => {
        if (ESCAPE_KEYS.has(key)) onClose?.()
    })

    return createPortal(
        <div className={cx(className, styles.overlay)} onClick={(e) => e.stopPropagation()}>
            <div className={cx(`container-${size}`, 'inset-md', styles.container)}>
                <div className={cx(styles.dialog)}>
                    <div className="d-flex align-items-start stacked-md">
                        {title ? (
                            <div className="mr-auto inline-xs">
                                <h2>{title}</h2>
                            </div>
                        ) : null}
                        {onClose ? (
                            <Button variant="secondaryText" size="sm" onClick={onClose}>
                                <ion-icon name="close-outline" style={{ fontSize: '20px' }} />
                            </Button>
                        ) : null}
                    </div>
                    {children}
                </div>
            </div>
        </div>,
        modalRoot!
    )
}
