import { FC, ReactNode } from 'react'
import cx from 'classnames'

type Props = {
    className?: string
    title: ReactNode
    action?: ReactNode
}

export const HeaderWithAction: FC<Props> = ({ title, action, className }) => {
    return (
        <div className={cx('d-flex align-items-center', className)}>
            <div className="mr-auto">{title}</div>
            <div>{action}</div>
        </div>
    )
}
