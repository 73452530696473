import { FC } from 'react'

import { Button } from 'ds/components'

type Props = { row: any }

export const SelectableCell: FC<Props> = ({ row }) => {
    const isSelected = row.getIsSelected()

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <Button
                size="sm"
                variant={isSelected ? 'primaryText' : 'secondaryText'}
                icon={isSelected ? 'check-square-fill' : 'square'}
                disabled={!row.getCanSelect()}
                onClick={row.getToggleSelectedHandler()}
            />
        </span>
    )
}
