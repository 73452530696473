import { makeApiRequest } from './apiUtils/makeApiRequest'

const uploadFileToS3 = async (file, s3directory) => {
    const presignedPost = await makeApiRequest({
        url: '/file/presign-upload',
        queryParams: { name: file.name, s3directory },
    })

    const { url, fields, fileUrl } = presignedPost

    try {
        const formData = new FormData()

        Object.entries(fields).forEach(([k, v]) => {
            formData.append(k, v)
        })

        formData.append('Content-Type', file.type)
        formData.append('file', file)

        const response = await fetch(url, {
            method: 'post',
            body: formData,
        })

        if (response.status === 204) {
            const uploadedUrl = fileUrl
            return uploadedUrl
        } else {
            console.error('Algo salió mal', response)
            return ''
        }
    } catch (e) {
        console.error(e)
    }
}

export { uploadFileToS3 }
