import { FC } from 'react'

import { Tooltip, Icon } from '../'

type Props = { text?: string }

export const SatValidationBadge: FC<Props> = ({ text }) => {
    const tooltip = (
        <Tooltip
            trigger={
                <div className="d-flex-center">
                    <Icon icon="PatchCheckFill" className="primary-400" />
                </div>
            }
        >
            Datos validados con el SAT
        </Tooltip>
    )
    if (!text) return tooltip

    return (
        <div className="d-flex align-items-center gap-2">
            {text}
            {tooltip}
        </div>
    )
}
