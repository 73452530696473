import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react'
import cx from 'classnames'

import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { Label } from 'ds/components/Label'
import { InputInfo } from '../InputInfo'

import styles from './style.module.scss'
import { FormContext } from 'lib/hooks/useForm'

type InputMask = 'multi-email'

interface InputProps
    extends Omit<
        DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        'onChange' | 'value'
    > {
    onChange: (val: string) => void
    value: string | undefined

    label?: ReactNode
    mask?: InputMask
    error?: string
    help?: JSX.Element | string
    maxEmails?: number
    trimWhitespace?: boolean
    defaultValue?: string | number | readonly string[] | undefined
    formContext?: FormContext
    readOnly?: boolean
}

const Input = ({
    className,
    type,
    label,
    required,
    error,
    help,
    mask,
    value = '',
    placeholder,
    maxEmails,
    trimWhitespace,
    onChange,
    formContext,
    readOnly,
    ...props
}: InputProps) => {
    return (
        <div className={className}>
            {label ? (
                <Label required={required || formContext?.required}>{label}</Label>
            ) : null}
            {mask === 'multi-email' ? (
                <ReactMultiEmail
                    placeholder={placeholder}
                    className={cx(styles.input, 'ease', { [styles.readOnly]: readOnly })}
                    emails={value ? value.split(',') : undefined}
                    onChange={(_emails) =>
                        !readOnly && onChange(_emails.slice(0, maxEmails).join(','))
                    }
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                {!readOnly ? (
                                    <span
                                        data-tag-handle
                                        onClick={() => removeEmail(index)}
                                    >
                                        ×
                                    </span>
                                ) : null}
                            </div>
                        )
                    }}
                />
            ) : (
                <input
                    className={cx(styles.input, {
                        [styles.readOnly]: readOnly,
                    })}
                    type={type}
                    value={value || ''}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    onChange={(e) =>
                        !readOnly &&
                        onChange(trimWhitespace ? e.target.value.trim() : e.target.value)
                    }
                    {...props}
                />
            )}
            <InputInfo error={error || formContext?.displayError} help={help} />
        </div>
    )
}

export { Input }
